<template>
<div>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <v-alert
      dense
      text
      type="success"
      dismissible
      v-model="alert"
    >
      Change Order <strong>saved</strong> successfully.
    </v-alert>
    <v-row justify="center">
      <v-col
        cols="12"
        md="12"
      >
        <base-material-card
        icon="mdi-clipboard-flow-outline"
        title="Change Order"
        class="px-5 py-3">
          <v-form
          ref="form"
          v-model="valid"
          lazy-validation>
            <v-container class="px-5 py-3">
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    label="Change Order Title"
                    v-model="Title"
                    :rules=itemRules
                    ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-textarea
                    label="Description"
                    v-model="HomeOwnerComment"
                    counter="250"
                    :rules=descriptionRules
                  />
                </v-col>
                <v-col
                  cols="12"
                  class="text-right"
                >
                  <v-btn
                    color="success"
                    class="mr-0"
                    @click="submitChangeOrder()"
                  >
                    Submit
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
  </div>
</template>
<script>
import axios from 'axios';
  export default {
    name: 'HomeownerChangeOrderRequest',
    data () {
      return {
        alert: false,
        valid: false,
        //TODO
        staff_email: 'steve@skelleymarketing.com',
        boulder_email : 'steve@skelleymarketing.com',
        itemRules: [
          v => !!v || 'Title is required',
          v => (v && v.length <= 25) || 'Max 25 characters.',
          ],
        descriptionRules: [
          v => !!v || 'Description is required',
          v => (v && v.length <= 250) || 'Max 250 characters.',
          ],
        OrderId : 0,
        HomeOwnerId : parseInt(localStorage.getItem("homeid")),
        VendorId : 1,
        Title : '',
        HomeOwnerComment : '',
        selected_user: {
            PrimaryEmailAddress:'',
            SecondaryEmailAddress:'',
            PrimaryFirstName: '',
            PrimaryLastName: '',
            SecondaryFirstName: '',
            SecondaryLastName: '',
            Address:'',
            City:'',
            State: '',
            Zip: '',
            BuildStart: '',
            BuildEnd:'',
            CellNumber:'',
            HomeNumber: '',
            WorkPhone:'',
            Role:'',
            Agent:''
        },
      }
    },

    created(){
      this.getUserInfo()
    },
    methods: {
      submitChangeOrder () {
        
        var order_body = {
          'order_id':this.OrderId,
          'home_owner_id':this.HomeOwnerId,
          'title':this.Title,
          'home_owner_comment':this.HomeOwnerComment,
          'agent_email': this.selected_user.Agent,
          'status_id': 1
        }

        this.alert = true;
        const theNewContent = {
            homeOwner: {
                ...this.selected_user
            },
            sectionData: {
              ...order_body
            },
        }
        axios
        .post(process.env.VUE_APP_SUBMIT_CHANGEORDER_URL,order_body)
        .then(response => {
          this.OrderId = response.data
          this.sendEmail([this.selected_user.PrimaryEmailAddress,this.selected_user.Agent,this.staff_email],this.boulder_email,"change_order_submit_notification",theNewContent, this.Title)
        })
        .catch(error => {
          console.log(error)
        })
      },
      sendEmail(to,from,template,content,title){

        var email_body ={
          "to": to,
          "from": from,
          "template_name": template,
          "template_data": {
            "content": content,
            "title" : title
          }
        }

        axios
        .post(process.env.VUE_APP_SEND_EMAIL_URL,email_body)
        .then(() => {
        })
        .catch(error => {
          console.log(error)
        })

      },
      getUserInfo(){
        axios
        .get(process.env.VUE_APP_GET_USER_URL_INFO,
        {
                params: {
                    home_id: this.HomeOwnerId
                }
        })
        .then(response => {
          const { data } = response
            if ( data ) {
              this.selected_user = data[0]
              }
        })
        .catch(error => {
        console.log(error)
        })
      },
      
    },
    computed: {
    permissions(){
        return {
          scope: localStorage.getItem("permissions")
        }
      }
    }
}
</script>